@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

body {
  margin: 0;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  background: none transparent !important;
}

.embed-root {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  &.dark {
    background: #191919;
  }
}

.embed-root .wrapper {
  width: 100%;
  height: 100%;

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: rgba(55, 53, 47, 0.4);
  }
}

.embed-root.dark {
  .no-data {
    color: rgba(255, 255, 255, 0.4);
  }
}